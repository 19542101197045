import { navigate } from "gatsby-link";
import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../components/Firebase";
import styled from "styled-components";
import LoadingWall from "../../components/LoadingWall";
import errorDictionary from "../../helpers/errorDictionary";

const RegisterRedirectContainer = styled.div`
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #302363;
  }
`;

const ErrorMessageText = styled.div`
  color: red;
  text-align: center;
`;


function Login() {
  const { firebase } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    firebase
      .login({ email: formValues.email, password: formValues.password })
      .catch((error) => {
        setErrorMessage(errorDictionary[error.code]);
        setLoading(false);
      });
  }

  function handleInputChange(e) {
    e.persist();
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
    setErrorMessage("");
  }

  return (
    <div class="page-header log-in wf-section">
      {loading && <LoadingWall />}
      <h1 class="h1-title">Log In</h1>
      <p class="subheading-text">Log in and get OSCEing. The first step to smashing your exams.</p>
      <div class="log-in-card">
        <div class="form-block w-form">
          <div class="text-block-5">🩺</div>
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            class="form"
            onSubmit={handleSubmit}
          >
            <input
              value={formValues.email}
              type="email"
              class="text-field-2 w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              placeholder="Email"
              id="email"
              required
              onChange={handleInputChange}
            />
            <input
              value={formValues.password}
              type="password"
              class="text-field-3 w-input"
              maxlength="256"
              name="password"
              data-name="Password"
              placeholder="Password"
              id="Password"
              required
              onChange={handleInputChange}
            />
            {!!errorMessage && (
              <div class="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            )}
            <input
              type="submit"
              value="Login"
              data-wait="Please wait..."
              class="button top-displacement w-button"
            />
          </form>
          {!!errorMessage && (
            <div>
              <br />
              <ErrorMessageText>{errorMessage}</ErrorMessageText>
            </div>
          )}
          <br />
          <RegisterRedirectContainer onClick={() => navigate("/registration")}>
            Need an account?
          </RegisterRedirectContainer>
          <RegisterRedirectContainer
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot your password?
          </RegisterRedirectContainer>
        </div>
      </div>
    </div>
  );
}

export default Login;
