import React, { useContext, useState } from "react";
import { FirebaseContext } from "../components/Firebase";
import Header from "../components/Header";
import Layout from "../layout/main";
import Usps from "../containers/Home/Usps";
import usePremiumStatus from "../components/Stripe/usePremiumStatus";
import { createCheckoutSession } from "../components/Stripe/createCheckoutSession";
import Login from "../containers/Authentication/Login";
import { navigate } from "gatsby-link";

const LoginPage = () => {
  const { user, firebase, loading } = useContext(FirebaseContext);

  user && (window.location.href="/dashboard")
  const userIsPremium = usePremiumStatus(user);
  return (
    // <Layout pageTitle="Login">
    // </Layout>
    <>
      <Login />
      <Usps />
    </>
  );
};

export default LoginPage;
